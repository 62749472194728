import axios from "axios";
import Header from "./Header";
import EventBus from "eventing-bus";
import { web3 } from "../store/web3";
import { ApiUrl } from "../store/config"
import { connect } from "react-redux";
import { useState, useRef } from "react";
import { kycSubmit, kynSubmit, setLoader } from "../store/actions/Auth";
import PersonalInfo from "../images/cifilogo.png";
import arrowup from "../images/arrowup.png";
import { useEffect } from "react";
import { networkId } from "../store/config";

function Kyn(props) {
  const wrapperRef = useRef(null);
  const [Name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [twitter, setTwitter] = useState("");
  const [businessDoc, setBusinessDoc] = useState("");
  const [socialSecurityNo, setSocialSecurityNo] = useState("");
  const [publicAddress, setPublicAddress] = useState("");
  useOutsideAlerter(wrapperRef);

  async function useOutsideAlerter() {
    const address = (await web3.currentProvider.enable())[0];
    if (typeof address !== "undefined") {
      setPublicAddress(address);
    }
  }

  function handleEditChange(e) {
    if ([e.target.name] == "Name") {
      setName(e.target.value);
    } else if ([e.target.name] == "website") {
      setWebsite(e.target.value);
    } else if ([e.target.name] == "facebook") {
      setFacebook(e.target.value);
    } else if ([e.target.name] == "linkedin") {
      setLinkedin(e.target.value);
    } else if ([e.target.name] == "twitter") {
      setTwitter(e.target.value);
    } else if ([e.target.name] == "businessDoc") {

      setBusinessDoc(e.target.files[0]);
    } else if ([e.target.name] == "socialSecurityNo") {
      setSocialSecurityNo(e.target.value);
    }
  }

  useEffect(() => {
    setName("");
    setWebsite("");
    setFacebook("");
    setLinkedin("");
    setTwitter("");
    setBusinessDoc("");
    setSocialSecurityNo("");
  }, [props.kycSubmitted]);

  function Submit() {
    try {

      // let { kycValue } = props;
      // console.log("********************* kycValue",kycValue);
      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (Name == "") {
        EventBus.publish("error", `Please enter non profit title`);
        return;
      }

      if (!Name.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter non profit title`);
        return;
      }

      if (!Name.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Non profit title must contain alphabets`);
        return;
      }

      if (socialSecurityNo == "") {
        EventBus.publish("error", `Please enter non profit title`);
        return;
      }

      if (!socialSecurityNo.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter social security`);
        return;
      }

      if (businessDoc == "") {
        EventBus.publish("error", `Please upload token banner`);
        return;
      }

      if (businessDoc == undefined) {
        EventBus.publish("error", `Please upload business document`);
        return;
      }

      let bodyFormData = new FormData();
      bodyFormData.append('document', businessDoc);
      bodyFormData.append('Name', Name);
      bodyFormData.append('publicAddress', publicAddress);
      bodyFormData.append('socialSecurityNo', socialSecurityNo);
      bodyFormData.append('website', website);
      bodyFormData.append('facebook', facebook);
      bodyFormData.append('twitter', twitter);
      bodyFormData.append('linkedin', linkedin);
      bodyFormData.append('chainId', networkId);

      props.setLoader({
        message: "Submitting KYN...",
        status: true,
      });

      axios({
        method: "post",
        url: `${ApiUrl}/kynSubmit`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          EventBus.publish("success", `KYN submitted!`);
          setName("");
          setWebsite("");
          setFacebook("");
          setLinkedin("");
          setTwitter("");
          setBusinessDoc();
          setSocialSecurityNo("");
          props.setLoader({
            message: "Submitting KYN...",
            status: false,
          });
        })
        .catch(function (error) {
          //handle error
          props.setLoader({
            message: "Submitting KYN...",
            status: false,
          });
          console.log(error.response.data.message);
          return EventBus.publish("error", error.response.data.message);
        });

    } catch (error) {
      EventBus.publish("error", error.message);
      props.setLoader({
        message: "Submitting KYN...",
        status: false,
      });
    }
  }


  return (
    <div>
      <Header />
      <div
        className="min-h-screen bg-no-repeat bg-cover popup-wrap"
        style={{ backgroundImage: "url(/images/new-bg.png)" }}
      >
        <div className="container py-20">
          <div className="grid items-center grid-cols-1 py-16 gap-y-5 md:grid-cols-2 gap-x-11 2xl:gap-x-20">
            <div>
              <img src={PersonalInfo} alt="" />
            </div>
            <div className="md:max-w-[645px]">
              <h4 className="text-[24px] font-normal md:text-[36px] text-dark-blue">
                Verify For Admin Access
              </h4>
              <div className="py-6 md:py-8 2xl:py-12 px-4 md:px-6 xl:px-8 rounded-[32px] border shadow-2xl border-cultured bg-white mt-4 md:mt-8 xl:mt-12">
                <div className="">
                  <h5 className="mb-4 text-xl font-normal md:text-2xl text-dark-blue">
                    Register your non-profit
                  </h5>
                  {Name !== "" ? (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="Name"
                      placeholder="Non-profit title"
                      onChange={handleEditChange}
                      value={Name}
                    />
                  ) : (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="Name"
                      placeholder="Non-profit title"
                      onChange={handleEditChange}
                      value={Name}
                    />
                  )}

                  {socialSecurityNo !== "" ? (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="socialSecurityNo"
                      placeholder="Owner social security number"
                      onChange={handleEditChange}
                      value={socialSecurityNo}
                    />
                  ) : (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="socialSecurityNo"
                      placeholder="Owner social security number"
                      onChange={handleEditChange}
                      value={socialSecurityNo}
                    />
                  )}

                  <input
                    type="url"
                    className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                    name="website"
                    placeholder="Website"
                    onChange={handleEditChange}
                    value={website}
                  />

                  <input
                    type="url"
                    className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                    name="facebook"
                    placeholder="Facebook"
                    onChange={handleEditChange}
                    value={facebook}
                  />

                  <input
                    type="url"
                    className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                    name="linkedin"
                    placeholder="Linkedin"
                    onChange={handleEditChange}
                    value={linkedin}
                  />

                  <input
                    type="url"
                    className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                    name="twitter"
                    placeholder="Twitter"
                    onChange={handleEditChange}
                    value={twitter}
                  />

                  {publicAddress !== "" ? (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      placeholder={publicAddress}
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      placeholder="Please connect XDC Pay Wallet!"
                      disabled
                    />
                  )}

                  <div className="form-group filewrap w-full h-12 px-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none">
                    {businessDoc == "" ?
                      <>
                        <span>Business document</span>
                        <div className="upload-btn"><img src={arrowup} alt="Upload File Icon" />Upload PDF File</div>
                        <input
                          type="file"
                          name="businessDoc"
                          placeholder="Business document"
                          accept=".pdf"
                          onChange={handleEditChange}
                        />
                      </> :
                      <>
                        <span>{businessDoc ? (businessDoc['name'] && businessDoc['name'].substring(0, 10) + '...') : "Upload PDF File*"}</span>
                        <div className="upload-btn"><img src={arrowup} alt="Upload File Icon" />Upload PDF File</div>
                        <input
                          type="file"
                          name="businessDoc"
                          placeholder={businessDoc ? (businessDoc['name'] && businessDoc['name'].substring(0, 10) + '...') : "Upload PDF File*"}
                          accept=".pdf"
                          onChange={handleEditChange}
                        />
                      </>
                    }
                  </div>
                </div>
                <button
                  className="inline-flex items-center justify-center w-full h-12 text-lg font-bold text-white md:h-16 md:mt-8 rounded-xl bg-gradient font-medium"
                  onClick={() => Submit()}
                >
                  Submit KYN
                  <img
                    src="/images/arrow-r-white.svg"
                    className="h-4 ml-4"
                    alt="arrow"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  kycSubmit, kynSubmit, setLoader
};

const mapStateToProps = ({ Auth }) => {
  let { kycStatus, kycValue, kycSubmitted } = Auth;
  return { kycStatus, kycValue, kycSubmitted };
};
export default connect(mapStateToProps, mapDispatchToProps)(Kyn);
